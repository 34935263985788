<template>
    <div class="order-submit">
        <div class="order-list">
            <div class="item" v-for="(item, i) in orderList" :key="i">
                <van-card
                    :num="item.count"
                    :price="item.price"
                    desc="描述信息"
                    title="药品全生命周期过程监管信息高度整合"
                    :thumb="imgUrl1"
                />
                <van-cell-group>
                    <van-cell title="配送服务" value="上门提货"/>
                    <van-cell title="订单备注" :value="item.remark?item.remark : '无备注'" class="my-cell" is-link @click="openPopup(i, item)"/>
                </van-cell-group>
            </div>
        </div>

        <van-submit-bar class="my-submit-bar" :price="totalOrder" button-text="提交订单" @submit="onSubmit">
        
        </van-submit-bar>
        <!-- 备注 -->
        <van-popup class="my-popup" v-model="show" position="bottom" round :style="{ height: '40%' }" closeable >
            <van-field
                v-model="remark"
                rows="2"
                autosize
                type="textarea"
                placeholder="请输入备注"
                show-word-limit
            />
            <my-btn :btnStr="'提交'" @onSubmit="submit" />
        </van-popup>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgUrl1: require('@/assets/img/horse1.jpg'),
            orderList: [],
            show: false,
            index: null,
            remark: ''
        }
    },
    computed: {
        totalOrder() {
            let total = 0
            this.orderList.map(ele=>{
                if(ele.checked){
                    total += (ele.price-0)*(ele.count-0)
                }
            })
            return total*100
        }
    },
    methods: {
        openPopup(i, item) {
            this.index = i
            this.show = true
            this.remark = item.remark
        },  
        onSubmit() {

        },
        submit() {
            this.orderList[this.index].remark = this.remark
            this.show = false
        }
    },
    mounted() {
        this.orderList = this.$help.storeTemp.get('order')
        this.orderList.forEach(ele=>{
            this.$set(ele, 'remark', '')
        })
        console.log(this.$help.storeTemp.get('order'))
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .order-submit{
        .my-submit-bar{
            z-index: 888;
            .van-button{
                //background: $main;
                border: 0;
            }
            .van-submit-bar__text{
                text-align: unset;
            }
        }
        .order-list{
            padding-bottom: 50px;
            .item{
                margin: 20px;
                //padding: 5px;
                border-radius: 19px;
                box-shadow: 0 0 6px 9px $boxShadow;
                .van-card{
                    border-radius: 19px 19px 0 0;
                }
                .van-cell-group{
                    border-radius: 0 0 19px 19px;
                    .my-cell{
                        border-radius: 0 0 19px 19px;
                    }
                    .van-cell__value{
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
        .my-popup{
            
            .van-field {
                margin-top: 40px;
                
                .van-cell__value{
                    background-color: #ededed;
                    textarea{
                        padding-left: 5px;
                        height: 150px !important;
                    }
                }
                //width: 320px;
            }
        }
    }
</style>